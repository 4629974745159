@font-face {
  font-family: 'Inferi';
  font-style: normal;
  font-weight: 100;
  src: url('/static/fonts/Inferi-Thin.otf');
  src: url('/static/fonts/Inferi-Thin.eot') format('embedded-opentype'),
    url('/static/fonts/Inferi-Thin.woff2') format('woff2'),
    url('/static/fonts/Inferi-Thin.woff') format('woff'),
    url('/static/fonts/Inferi-Thin.otf') format('opentype');
}

@font-face {
  font-family: 'Inferi';
  font-weight: 100;
  font-style: italic;
  src: url('/static/fonts/Inferi-ThinItalic.otf');
  src: url('/static/fonts/Inferi-ThinItalic.eot') format('embedded-opentype'),
    url('/static/fonts/Inferi-ThinItalic.woff2') format('woff2'),
    url('/static/fonts/Inferi-ThinItalic.woff') format('woff'),
    url('/static/fonts/Inferi-ThinItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Inferi';
  font-weight: 200;
  font-style: normal;
  src: url('/static/fonts/Inferi-Light.otf');
  src: url('/static/fonts/Inferi-Light.eot') format('embedded-opentype'),
    url('/static/fonts/Inferi-Light.woff2') format('woff2'),
    url('/static/fonts/Inferi-Light.woff') format('woff'),
    url('/static/fonts/Inferi-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Inferi';
  font-weight: 200;
  font-style: italic;
  src: url('/static/fonts/Inferi-LightItalic.otf');
  src: url('/static/fonts/Inferi-LightItalic.eot') format('embedded-opentype'),
    url('/static/fonts/Inferi-LightItalic.woff2') format('woff2'),
    url('/static/fonts/Inferi-LightItalic.woff') format('woff'),
    url('/static/fonts/Inferi-LightItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Inferi';
  font-weight: 300;
  font-style: italic;
  src: url('/static/fonts/Inferi-BookItalic.otf');
  src: url('/static/fonts/Inferi-BookItalic.eot') format('embedded-opentype'),
    url('/static/fonts/Inferi-BookItalic.woff2') format('woff2'),
    url('/static/fonts/Inferi-BookItalic.woff') format('woff'),
    url('/static/fonts/Inferi-BookItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Inferi';
  font-weight: 300;
  font-style: normal;
  src: url('/static/fonts/Inferi-Book.otf');
  src: url('/static/fonts/Inferi-Book.eot') format('embedded-opentype'),
    url('/static/fonts/Inferi-Book.woff2') format('woff2'),
    url('/static/fonts/Inferi-Book.woff') format('woff'),
    url('/static/fonts/Inferi-Book.otf') format('opentype');
}

@font-face {
  font-family: 'Inferi';
  font-weight: 400;
  font-style: italic;
  src: url('/static/fonts/Inferi-RegularItalic.otf');
  src: url('/static/fonts/Inferi-RegularItalic.eot') format('embedded-opentype'),
    url('/static/fonts/Inferi-RegularItalic.woff2') format('woff2'),
    url('/static/fonts/Inferi-RegularItalic.woff') format('woff'),
    url('/static/fonts/Inferi-RegularItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Inferi';
  font-style: normal;
  font-weight: 400;
  src: url('/static/fonts/Inferi-Regular.otf');
  src: url('/static/fonts/Inferi-Regular.eot') format('embedded-opentype'),
    url('/static/fonts/Inferi-Regular.woff2') format('woff2'),
    url('/static/fonts/Inferi-Regular.woff') format('woff'),
    url('/static/fonts/Inferi-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Inferi';
  font-style: normal;
  font-weight: 700;
  src: url('/static/fonts/Inferi-Bold.otf');
  src: url('/static/fonts/Inferi-Bold.eot') format('embedded-opentype'),
    url('/static/fonts/Inferi-Bold.woff2') format('woff2'),
    url('/static/fonts/Inferi-Bold.woff') format('woff'),
    url('/static/fonts/Inferi-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Inferi';
  font-weight: 700;
  font-style: italic;
  src: url('/static/fonts/Inferi-BoldItalic.otf');
  src: url('/static/fonts/Inferi-BoldItalic.eot') format('embedded-opentype'),
    url('/static/fonts/Inferi-BoldItalic.woff2') format('woff2'),
    url('/static/fonts/Inferi-BoldItalic.woff') format('woff'),
    url('/static/fonts/Inferi-BoldItalic.otf') format('opentype');
}
